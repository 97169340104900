import React from "react";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import LoginForm from "../components/UI/Forms/LoginForm/LoginForm";
import Footer from "../components/Footer/Footer";

const AdminLogin = ({ title }) => {
  document.title = title;

  return (
    <PageWrapper>
      <Header />
      <LoginForm title="Admin Login" submitButton={"Log In"} />
      <Footer />
    </PageWrapper>
  );
};

export default AdminLogin;
