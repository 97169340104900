import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
  energys: [],
  energyLoading: true,
  energyLoadingError: false,
  colors: [],
  forceRefresh: false,
  displayNavigation: false,
};

const energySlice = createSlice({
  name: "energy",
  initialState,
  reducers: {
    updateEnergys(state, action) {
      state.energys = action.payload;
      state.energyLoading = false;
    },
    setEnergysLoading(state) {
      state.energyLoading = true;
    },
    setEnergysLoadingError(state, action) {
      state.energyLoadingError = action.payload;
      state.energyLoading = false;
    },
    updateColors(state, action) {
      state.colors = action.payload;
    },
    forceRefresh(state, action) {
      state.forceRefresh = action.payload;
    },
    displayNavigation(state, action) {
      state.displayNavigation = action.payload;
    },
  },
});

const store = configureStore({
  reducer: energySlice.reducer,
});

export const energyActions = energySlice.actions;

export default store;
