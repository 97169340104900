import React from "react";
import classes from "./ThreeDotsSpinner.module.css";

const ThreeDotsSpinner = ({ visible = true, className = "", color = "" }) => {
  return (
    visible && (
      <div className={`${classes.container} ${className}`}>
        <div
          style={color.length > 0 ? { backgroundColor: `#${color}` } : {}}
          className={classes.dot1}
        ></div>
        <div
          style={color.length > 0 ? { backgroundColor: `#${color}` } : {}}
          className={classes.dot2}
        ></div>
        <div
          style={color.length > 0 ? { backgroundColor: `#${color}` } : {}}
          className={classes.dot3}
        ></div>
      </div>
    )
  );
};

export default ThreeDotsSpinner;
