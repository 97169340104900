import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import axios from "axios";

import classes from "./EnergyItem.module.css";
import Flag from "react-country-flag";
import { BiEdit } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { VscCircleLargeFilled } from "react-icons/vsc";
import Image from "../UI/Image/Image";
import Image404 from "../../assets/energy404.jpg";
import Modal from "../UI/Modal/Modal";

const EnergyItem = ({
  skeleton = false,
  id,
  title,
  imageUrl = "",
  resizedImageUrl = "",
  country,
  color,
  colors = [],
  amount,
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [modal, showModal] = useState(0); //0 - false | 1 - type:warning | 2 - type:success
  const navigate = useNavigate();

  const editHandler = () => {};

  const deleteHandler = () => showModal(1);
  const modalCloseHandler = () => {
    if (modal === 1) showModal(0);
    else if (modal === 2) {
      navigate(0);
    }
  };

  const confirmDeleteHandler = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/energys/${id}`, {
        token: currentUser.token,
      });
      showModal(2);
    } catch (err) {
      console.log(err);
    }
  };

  const imageAfterLoadHandler = () => setImageLoading(false);

  //get color code from color
  const index = colors.findIndex((x) => x.color === color);
  let colorCode = undefined;
  if (colors.length > 0) colorCode = colors[index].code;
  if (colorCode === undefined) colorCode = color;

  return (
    <>
      {modal === 1 && (
        <Modal
          title="Warning!"
          type="warning"
          message={`Are you sure you want to delete "${title}" ?`}
          buttonYesText="Yes"
          buttonNoText="Cancel"
          onClose={modalCloseHandler}
          onConfirm={confirmDeleteHandler}
        />
      )}
      {modal === 2 && (
        <Modal
          title="Success!"
          type="success"
          message={`You have successfully deleted "${title}" .`}
          buttonYesText="Ok"
          onConfirm={modalCloseHandler}
          onClose={modalCloseHandler}
        />
      )}

      {!skeleton ? (
        <div className={classes.itemContainer}>
          <Link
            to={`/energy/${id}`}
            target="_blank"
            className={classes.imageContainer}
          >
            <Image
              alt={title}
              src={`/upload/energy/${resizedImageUrl}`}
              onError={Image404}
              afterLoad={imageAfterLoadHandler}
            />
            {imageLoading && <div className={classes.loadingImage}></div>}

            <div className={classes.amount}>
              <VscCircleLargeFilled
                color={colorCode}
                className={classes.color}
              />
              <p>{amount + " ml"}</p>
            </div>
            <Flag
              countryCode={country}
              svg
              style={{
                position: "absolute",
                bottom: "5px",
                left: "5px",
                width: "38px",
                height: "auto",
                opacity: "0.6",
                border: "1px solid black",
                filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 1.2))",
                borderRadius: "7px",
                display: "flex",
              }}
              title={country.toUpperCase()}
            />
          </Link>
          <p className={classes.title}>{title}</p>

          {currentUser && (
            <>
              <BiEdit
                className={`${classes.icon} ${classes.editIcon}`}
                onClick={editHandler}
              />
              <BiTrash
                className={`${classes.icon} ${classes.deleteIcon}`}
                onClick={deleteHandler}
              />
            </>
          )}
        </div>
      ) : (
        <div
          className={`${classes.itemContainer} ${classes.itemContainerSkeleton}`}
        >
          <div className={classes.imageContainer}>
            <div
              className={`${classes.amount} ${classes.amountSkeleton}`}
            ></div>
            <div
              className={`${classes.amountSkeleton} ${classes.countrySkeleton}`}
            ></div>
          </div>
          <div className={`${classes.title} ${classes.titleSkeleton}`}>
            <p></p>
          </div>
          {currentUser && (
            <>
              <div
                className={`${classes.icon} ${classes.editIcon} ${classes.iconSkeleton}`}
              ></div>
              <div
                className={`${classes.icon} ${classes.deleteIcon} ${classes.iconSkeleton}`}
              ></div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EnergyItem;
