import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./LoginForm.module.css";
import FormWrapper from "../FormWrapper/FormWrapper";
import Input from "../../Input/Input";

import { AuthContext } from "../../../../context/authContext";

const LoginForm = ({ title, submitButton }) => {
  const navigate = useNavigate();
  const { login, currentUser } = useContext(AuthContext);

  //redirect if already logged in
  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  });

  const [enteredUsername, setEnteredUsername] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (
      enteredUsername.trim().length > 0 &&
      enteredPassword.trim().length > 0
    ) {
      setUsernameError("");
      setPasswordError("");
      setErrorMessage("");
      setLoading(true);

      try {
        await login({ username: enteredUsername, password: enteredPassword });
        navigate("/");
      } catch (err) {
        if (err.response.data === "User not found!")
          setUsernameError(err.response.data);
        else if (err.response.data === "Wrong password!")
          setPasswordError(err.response.data);
        else {
          setErrorMessage("Internal Server Error!");
        }
        setLoading(false);
      }
    } else {
      if (enteredUsername.trim().length < 1)
        setUsernameError("The field is required!");
      else setUsernameError("");
      if (enteredPassword.trim().length < 1)
        setPasswordError("The field is required!");
      else setPasswordError("");
    }
  };

  const closeHandler = () => navigate("/");

  const usernameChangeHandler = (e) => {
    setEnteredUsername(e.target.value);
  };

  const passwordChangeHandler = (e) => {
    setEnteredPassword(e.target.value);
  };

  return (
    <FormWrapper
      title={title}
      onSubmit={submitHandler}
      submitButton={submitButton}
      disabled={loading}
      onClose={closeHandler}
    >
      <Input
        type="text"
        placeholder="Username:"
        id="username"
        onChange={usernameChangeHandler}
        error={usernameError.length > 0}
        label={true}
        labelText={usernameError.length > 0 && usernameError}
      />

      <Input
        type="password"
        placeholder="Password:"
        id="password"
        onChange={passwordChangeHandler}
        error={passwordError.length}
        label={true}
        labelText={passwordError.length > 0 && passwordError}
      />

      {errorMessage.length > 0 && (
        <label className={classes.errorMessage}>{errorMessage}</label>
      )}
    </FormWrapper>
  );
};

export default LoginForm;
