import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../../../context/authContext";
import { useDispatch } from "react-redux";
import { energyActions } from "../../../../store";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { capitalizeFirstLetter } from "../../../../utils";

import classes from "./AdminPanelForm.module.css";
import FormWrapper from "../FormWrapper/FormWrapper";
import Input from "../../Input/Input";
import Modal from "../../Modal/Modal";
import Tag from "../../Tag/Tag";
import ThreeDotsSpinner from "../../Spinners/ThreeDotsSpinner";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      1000,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

const AdminPanel = ({ title, submitButton }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const requiredError = "The field is required!";
  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("250");
  const [color, setColor] = useState("");
  const [country, setCountry] = useState("");
  const [file, setFile] = useState("");
  const [resizedFile, setResizedFile] = useState("");

  const [nameError, setNameError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [colorError, setColorError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [fileError, setFileError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [modal, showModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/settings`
        );
        setColors(JSON.parse(res.data[0].colors));
        setCountries(JSON.parse(res.data[0].countries));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const nameChangeHandler = (e) => {
    setName(e.target.value);

    if (e.target.value.trim().length < 1) setNameError(requiredError);
    else setNameError("");
  };

  const amountChangeHandler = (e) => {
    setAmount(e.target.value);

    if (e.target.value.trim().length < 1) setAmountError(requiredError);
    else setAmountError("");
  };

  const colorSelectHandler = (color) => {
    setColor(color);
    setColorError("");
  };

  const countrySelectHandler = (country) => {
    setCountry(country);
    setCountryError("");
  };

  /*const fileChangeHandler = async (e) => {
    setFile(e.target.files[0]);

    const image = await resizeFile(e.target.files[0]);
    //const resized = await dataURIToBlob(image);
    //setResizedFile(resized);
    setResizedFile(image);

    setFileError("");
  };*/

  const fileChangeHandler = async (event) => {
    try {
      const file = event.target.files[0];
      console.log("FILE:", file);
      setFile(file);
      const image = await resizeFile(file);
      //const resized = await dataURIToBlob(image);
      //setResizedFile(resized);
      setResizedFile(image);
      setFileError("");
    } catch (err) {
      console.log(err);
    }
  };

  const upload = async (uploadFile) => {
    try {
      const formData = new FormData();
      formData.append("file", uploadFile);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/upload`,
        formData
      );
      return res.data;
    } catch (err) {
      setUploading(false);
      console.log(err);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (name.trim().length < 1) return setNameError(requiredError);
    if (amount.trim().length < 1) return setAmountError(requiredError);
    if (color.trim().length < 1) return setColorError(requiredError);
    if (country.trim().length < 1) return setCountryError(requiredError);
    if (file.length < 1) return setFileError(requiredError);

    setUploading(true);
    const imageUrl = await upload(file);
    const resizedImageUrl = await upload(resizedFile);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/energys`, {
        token: currentUser.token,
        name,
        amount,
        color: capitalizeFirstLetter(color),
        country,
        imageUrl,
        resizedImageUrl,
      });

      setUploading(false);
      showModal(true);
    } catch (err) {
      setUploading(false);
      setErrorMessage("Internal Server Error!");
      console.log(err);
    }
  };

  const closeHandler = () => navigate("/");

  const closeModalHandler = () => {
    dispatch(energyActions.forceRefresh(true));
    navigate("/");
  };

  return (
    <>
      <FormWrapper
        title={title}
        onSubmit={submitHandler}
        submitButton={
          uploading ? (
            <ThreeDotsSpinner className={classes.loading} />
          ) : (
            submitButton
          )
        }
        onClose={closeHandler}
        className={classes.form}
      >
        <Input
          type="text"
          placeholder="Energy Name:"
          onChange={nameChangeHandler}
          id="title"
          error={!!nameError}
          label={true}
          labelText={nameError}
        />

        <Input
          type="number"
          placeholder="Amount (ml):"
          onChange={amountChangeHandler}
          autoComplete="off"
          id="amount"
          value={amount}
          error={!!amountError}
          label={true}
          labelText={amountError}
        />

        <div
          className={
            colorError
              ? `${classes.selectContainer} ${classes.inputError2}`
              : classes.selectContainer
          }
        >
          {loading && <ThreeDotsSpinner />}
          {colors.map((c) => (
            <Tag
              key={c.code}
              type="radio"
              title={c.color}
              borderColor={c.code}
              selected={color === c.color && true}
              onClick={() => colorSelectHandler(c.color)}
            />
          ))}
        </div>
        <label>{colorError}</label>

        <div
          className={
            countryError
              ? `${classes.selectContainer} ${classes.inputError2}`
              : classes.selectContainer
          }
        >
          {loading && <ThreeDotsSpinner />}
          {countries.map((c) => (
            <Tag
              key={c.code}
              type="radio"
              title={c.code}
              flag={c.code}
              selected={country === c.code && true}
              textTransform="uppercase"
              onClick={() => countrySelectHandler(c.code)}
            />
          ))}
        </div>
        <label>{countryError}</label>

        <Input
          type="file"
          id="file"
          onChange={fileChangeHandler}
          style={{ display: "none" }}
          accept="image/*"
          label={false}
        />
        <label
          className={
            !!fileError
              ? `${classes.uploadImage} ${classes.inputError}`
              : classes.uploadImage
          }
          htmlFor="file"
        >
          Upload Image
        </label>
        <label>{fileError}</label>

        {errorMessage.length > 0 && (
          <label className={classes.errorMessage}>{errorMessage}</label>
        )}
      </FormWrapper>

      {modal && (
        <Modal
          title="Success!"
          type="success"
          message={`You have successfully added energy "${name}" .`}
          buttonYesText="Ok"
          onConfirm={closeModalHandler}
          onClose={closeModalHandler}
        />
      )}
    </>
  );
};

export default AdminPanel;
