import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classes from "./EnergyDetails.module.css";
import Error404 from "../UI/Error404/Error404";
import Image from "../UI/Image/Image";
import Image404 from "../../assets/energy404.jpg";
import Flag from "react-country-flag";

const EnergyDetails = ({ id }) => {
  const energys = useSelector((state) => state.energys);
  const [energy, setEnergy] = useState(null);
  const [error404, setError404] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const index = energys.findIndex((x) => x.id === parseInt(id));

  useEffect(() => {
    if (index === -1) {
      if (energys.length > 0) setError404(true);
    } else {
      setError404(false);
      setEnergy(energys[index]);
    }
  }, [energys, index]);

  const imageAfterLoadHandler = () => setImageLoading(false);

  let render;
  if (error404) render = <Error404 errorText="Energy not found" />;
  else
    render = (
      <div className={classes.container}>
        {energy !== null && (
          <>
            <div className={classes.itemContainer}>
              <div className={classes.imgContainer}>
                <Image
                  alt={energy.title}
                  src={`/upload/energy/${energy.imageUrl}`}
                  onError={Image404}
                  afterLoad={imageAfterLoadHandler}
                />
                {imageLoading && <div className={classes.loadingImage}></div>}

                <p className={classes.amount}>{energy.amount + " ml"}</p>

                <div className={classes.flag}>
                  <Flag
                    countryCode={energy.country}
                    svg
                    style={{
                      width: "50px",
                      height: "auto",
                      borderRadius: "7px",
                    }}
                    title={energy.country.toUpperCase()}
                  />
                </div>
              </div>
              <p className={classes.title}>{energy.name}</p>
            </div>
          </>
        )}
      </div>
    );

  return render;
};

export default EnergyDetails;
