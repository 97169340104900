import React from "react";

import classes from "./Modal.module.css";
import Backdrop from "../Backdrop/Backdrop";
import Button from "../Button/Button";

import { IoIosWarning } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

const Modal = ({
  title,
  type = "warning",
  message = "Are you sure?",
  buttonYesText = "",
  buttonNoText = "",
  onClose,
  onConfirm,
}) => {
  let typeClassColor = "",
    typeClassBg = "",
    typeIcon = 1;
  if (type === "warning") {
    typeClassColor = classes.warning;
    typeClassBg = classes.warningBg;
    typeIcon = 1;
  } else if (type === "success") {
    typeClassColor = classes.success;
    typeClassBg = classes.successBg;
    typeIcon = 2;
  }

  return (
    <Backdrop onClose={onClose} className={classes.backdrop}>
      <div className={classes.container}>
        <div className={`${classes.titleContainer} ${typeClassBg}`}>
          <h1>{title}</h1>
          <FaTimes className={classes.closeIcon} onClick={onClose} />
        </div>

        <div className={classes.messageContainer}>
          <div className={classes.message}>
            {typeIcon === 1 && (
              <IoIosWarning className={`${classes.icon} ${typeClassColor}`} />
            )}
            {typeIcon === 2 && (
              <FaCheckCircle className={`${classes.icon} ${typeClassColor}`} />
            )}
            <p>{message}</p>
          </div>
          <div className={classes.buttonsContainer}>
            {!!buttonNoText && (
              <Button
                title={buttonNoText}
                className={`${classes.button} ${classes.no}`}
                onClick={onClose}
              />
            )}
            {!!buttonYesText && (
              <Button
                title={buttonYesText}
                className={`${classes.button} ${classes.yes} ${typeClassBg}`}
                onClick={onConfirm}
              />
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default Modal;
