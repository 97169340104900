import React from "react";
import { useParams } from "react-router-dom";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import EnergyDetails from "../components/EnergyDetails/EnergyDetails";
import Footer from "../components/Footer/Footer";

const Energy = () => {
  document.title = "Nedim Džanić - Details";

  const { id } = useParams();

  return (
    <PageWrapper>
      <Header />
      <EnergyDetails id={id} />
      <Footer />
    </PageWrapper>
  );
};

export default Energy;
