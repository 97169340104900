import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Image = ({
  alt = "",
  width,
  height,
  style,
  src,
  className,
  beforeLoad,
  afterLoad,
  onError,
}) => (
  <LazyLoadImage
    alt={alt}
    height={height}
    width={width}
    style={style}
    src={src}
    className={className}
    effect="blur"
    beforeLoad={beforeLoad}
    afterLoad={afterLoad}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = onError;
    }}
  />
);

export default Image;
