import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { energyActions } from "../../store";
import { Link } from "react-router-dom";
import classes from "./Header.module.css";

import Navigation from "../UI/Navigation/Navigation";
import { TfiMenu } from "react-icons/tfi";
import Logo from "../../assets/Logo-can.png";

const Header = () => {
  const navigationDisplay = useSelector((state) => state.displayNavigation);
  const [navigationOnClosing, setNavigationOnClosing] = useState(false);

  const dispatch = useDispatch();

  const navigationHandler = (show) => {
    if (show === true) dispatch(energyActions.displayNavigation(true));
    else {
      setNavigationOnClosing(true);
      setTimeout(() => {
        setNavigationOnClosing(false);
        dispatch(energyActions.displayNavigation(false));
      }, 700);
    }
  };

  return (
    <>
      <header className={classes.container}>
        <TfiMenu
          className={classes.menu}
          onClick={() => navigationHandler(true)}
        />

        <Link to={"/"} className={classes.logo}>
          <img src={Logo} alt="" />
        </Link>
      </header>

      {navigationDisplay && (
        <Navigation
          onClose={() => navigationHandler(false)}
          onClosing={navigationOnClosing}
        />
      )}
    </>
  );
};

export default Header;
