import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../context/authContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import classes from "./AdminSettingsForm.module.css";
import FormWrapper from "../FormWrapper/FormWrapper";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import Tag from "../../Tag/Tag";
import ThreeDotsSpinner from "../../Spinners/ThreeDotsSpinner";

const AdminSettingsForm = ({ title, submitButton }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState([]);
  const [edited, setEdited] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [colorName, setColorName] = useState("");
  const [colorError, setColorError] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/settings`
        );
        setColors(JSON.parse(res.data[0].colors));
        setCountries(JSON.parse(res.data[0].countries));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const colorChangeHandler = (e) => setSelectedColor(e.target.value);

  const colorNameChangeHandler = (e) => {
    setColorName(e.target.value);

    if (e.target.value.trim().length < 1) setColorError(true);
    else setColorError(false);
  };

  const addColorHandler = () => {
    if (colorName.trim().length < 1) return setColorError(true);

    const newColor = { color: colorName, code: selectedColor };

    //check color is already in colors
    if (
      colors.findIndex((x) => x.code === newColor.code) !== -1 ||
      colors.findIndex((x) => x.color === newColor.color) !== -1
    )
      return setColorError(true);

    setEdited(true);
    setColorName("");

    setColors((prevState) => {
      return [...prevState, newColor];
    });
  };

  const removeColorHandler = (color) => {
    const index = colors.findIndex((x) => x.color === color);

    const remove = colors;
    remove.splice(index, 1);
    setColors([...remove]);

    setEdited(true);
  };

  const countryCodeChangeHandler = (e) => {
    setCountryCode(e.target.value);

    if (e.target.value.trim().length < 1) setCountryError(true);
    else setCountryError(false);
  };

  const addCountryHandler = () => {
    if (countryCode.trim().length < 1) return setCountryError(true);

    const newCountry = { code: countryCode.toUpperCase() };

    //check country is already in countries
    if (countries.findIndex((x) => x.code === newCountry.code) !== -1)
      return setCountryError(true);

    setEdited(true);
    setCountryCode("");

    setCountries((prevState) => {
      return [...prevState, newCountry];
    });
  };

  const removeCountryHandler = (code) => {
    const index = countries.findIndex((x) => x.code === code);

    const remove = countries;
    remove.splice(index, 1);
    setCountries([...remove]);

    setEdited(true);
  };

  const closeHandler = () => navigate("/");

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URI}/settings/1`, {
        token: currentUser.token,
        colors: JSON.stringify(colors),
        countries: JSON.stringify(countries),
      });
      return navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormWrapper
      title={title}
      className={classes.form}
      submitButton={submitButton}
      onSubmit={submitHandler}
      onClose={closeHandler}
      disabled={!edited}
    >
      <label>Colors</label>
      <div className={classes.colorContainer}>
        <div className={classes.colorsContainer}>
          {loading && <ThreeDotsSpinner />}
          {colors.map((color) => (
            <Tag
              key={color.code}
              title={color.color}
              borderColor={color.code}
              onClick={() => removeColorHandler(color.color)}
            />
          ))}
        </div>
        <div className={classes.choseContainer}>
          <input
            type="color"
            value={selectedColor}
            onChange={colorChangeHandler}
          ></input>
          <Input
            type="text"
            placeholder="Color text:"
            autoComplete="off"
            error={!!colorError}
            onChange={colorNameChangeHandler}
            value={colorName}
          />
          <Button
            title="Add Color"
            className={classes.addButton}
            onClick={addColorHandler}
          />
        </div>
      </div>

      <label>Countries</label>
      <div className={classes.colorContainer}>
        <div className={classes.colorsContainer}>
          {loading && <ThreeDotsSpinner />}
          {countries.map((country) => (
            <Tag
              key={country.code}
              title={country.code}
              flag={country.code}
              textTransform="uppercase"
              onClick={() => removeCountryHandler(country.code)}
            />
          ))}
        </div>
        <div className={classes.choseContainer}>
          <Input
            type="text"
            placeholder="Country code:"
            autoComplete="off"
            error={!!countryError}
            onChange={countryCodeChangeHandler}
            value={countryCode}
          />
          <Button
            title="Add Country"
            className={classes.addButton}
            onClick={addCountryHandler}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

export default AdminSettingsForm;
