import React from "react";
import classes from "./Footer.module.css";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className={classes.footer}>
      Copyright ©{year} | Development and design by noSmoke
    </footer>
  );
};

export default Footer;
