import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { energyActions } from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContext } from "./context/authContext";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import Redirect from "./pages/Redirect";
import AdminLogin from "./pages/AdminLogin";
import AdminPanel from "./pages/AdminPanel";
import AdminSettings from "./pages/AdminSettings";
import Energy from "./pages/Energy";
import Search from "./pages/Search";

const App = () => {
  const dispatch = useDispatch();
  const forceRefresh = useSelector((state) => state.forceRefresh);
  const { currentUser, logout } = useContext(AuthContext);
  const [isLogged, setIsLogged] = useState(false);

  //check is logged
  useEffect(() => {
    const checkIsLogged = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URI}/auth/isLogged`, {
          token: currentUser.token,
        });
        setIsLogged(true);
      } catch (err) {
        setIsLogged(false);
        logout();
      }
    };

    if (currentUser !== null && currentUser.token !== undefined)
      checkIsLogged();
    else setIsLogged(false);
  }, [currentUser, logout]);

  //fetch energys
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/energys`
        );
        dispatch(energyActions.updateEnergys(res.data));
        dispatch(energyActions.forceRefresh(false));
      } catch (err) {
        dispatch(
          energyActions.setEnergysLoadingError({
            message: err.message,
            status: err.request.status,
          })
        );
      }
    };
    fetchData();
  }, [dispatch, forceRefresh]);

  //fetch settings
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/settings`
        );
        dispatch(energyActions.updateColors(JSON.parse(res.data[0].colors)));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route path="*" element={<Page404 />} />
        <Route
          path="adminlogin"
          element={!isLogged ? <AdminLogin title={"Admin Login"} /> : <Home />}
        />
        <Route
          path="adminpanel"
          element={
            isLogged ? <AdminPanel title={"Admin Panel"} /> : <Redirect />
          }
        />
        <Route
          path="adminsettings"
          element={
            isLogged ? <AdminSettings title={"Admin Settings"} /> : <Redirect />
          }
        />
        <Route path="energy/:id" element={<Energy />} />
        <Route path="search" element={<Search />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
