import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import classes from "./EnergyList.module.css";
import classes2 from "./SearchEnergyList.module.css";
import EnergyItem from "../EnergyItem/EnergyItem";
import ThreeDotsSpinner from "../UI/Spinners/ThreeDotsSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cssColors } from "../../styles/colors";
import PageError from "../PageError/PageError";
import { FcSearch } from "react-icons/fc";
import { BiSad } from "react-icons/bi";
import { VscCircleLargeFilled } from "react-icons/vsc";
import Flag from "react-country-flag";

const SearchEnergyList = ({ search }) => {
  const allEnergys = useSelector((state) => state.energys);
  const [energys, setEnergys] = useState([]);
  const [energyLoading, setEnergyLoading] = useState(true);
  const [energyLoadingError, setEnergyLoadingError] = useState(false);
  const [energysRender, setEnergysRender] = useState([]);
  const numberOfRenderElements = 20;
  const colors = useSelector((state) => state.colors);

  //fetch energys
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/energys/${search.search}/${search.color}/${search.country}`
        );
        setEnergys(res.data);
        setEnergyLoading(false);
      } catch (err) {
        setEnergyLoadingError(err);
        setEnergyLoading(false);
      }
    };
    fetchData();
  }, [search]);

  //set first render group
  useEffect(() => {
    if (energys.length > 0) {
      let arr = [];
      for (let i = 0; i < numberOfRenderElements; i++) {
        if (energys[i] !== undefined) arr.push(energys[i]);
      }
      setEnergysRender(arr);
    } else setEnergysRender([]);
  }, [energys]);

  //fetch more data on scroll down
  const fetchMoreData = () => {
    setTimeout(() => {
      const rendered = energysRender.length;
      let newRenderGroup = [];

      for (
        let i = rendered;
        i < rendered + numberOfRenderElements && i < energys.length;
        i++
      )
        newRenderGroup.push(energys[i]);

      setEnergysRender([...energysRender, ...newRenderGroup]);
    }, 2000);
  };

  const colorCodeIndex = colors.findIndex((x) => x.color === search.color);

  let procent = allEnergys.length / energys.length;
  procent = (100 / procent).toFixed(2);

  let render;
  let counterComponent;
  counterComponent = (
    <div className={classes.counterContainer}>
      <CircularProgressbarWithChildren
        value={energysRender.length > 0 && !energyLoading ? energys.length : 0}
        maxValue={
          energysRender.length > 0 && !energyLoading ? allEnergys.length : 100
        }
        text={energysRender.length > 0 && !energyLoading ? energys.length : ""}
        styles={{
          root: { width: "90px" },
          path: {
            stroke: cssColors.c_main_2_dark,
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 0.1s ease 0s",
            strokeWidth: "8px",
          },
          trail: {
            stroke: cssColors.c_main_2_darkest_1,
            strokeLinecap: "butt",
            strokeWidth: "4px",
          },
          text: {
            fill: cssColors.c_main,
            fontSize: "35px",
            fontFamily: "Roboto, Arial",
          },
        }}
      >
        {energyLoading ? (
          <ThreeDotsSpinner className={classes.loadingDotsInCircleProgress} />
        ) : (
          <p className={classes2.procent}>{`${procent}%`}</p>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );

  const searchComponent = (
    <div className={classes2.searchCounterConteiner}>
      <p>Search result for</p>
      <div className={classes2.flex}>
        {search.search !== null && <p>{search.search}</p>}
        {search.color !== null && colorCodeIndex !== -1 && (
          <VscCircleLargeFilled
            color={colors[colorCodeIndex].code}
            className={classes2.color}
          />
        )}
        {search.country !== null && (
          <Flag
            countryCode={search.country}
            svg
            style={{
              opacity: "0.9",
            }}
          />
        )}
      </div>
    </div>
  );

  //energys loaded and energys length not 0
  if (energysRender.length > 0 && !energyLoading) {
    render = (
      <>
        {searchComponent}
        {counterComponent}
        <InfiniteScroll
          dataLength={energysRender.length}
          next={fetchMoreData}
          hasMore={energysRender.length < energys.length ? true : false}
          loader={<ThreeDotsSpinner className={classes.loadingDots} />}
          className={classes.container}
        >
          {energysRender.map((energy) => (
            <EnergyItem
              key={energy.id + energy.imageUrl}
              id={energy.id}
              title={energy.name}
              imageUrl={energy.imageUrl}
              resizedImageUrl={energy.resizedImageUrl}
              country={energy.country}
              color={energy.color}
              colors={colors}
              amount={energy.amount}
            />
          ))}
        </InfiniteScroll>
      </>
    );
    //energys on loading
  } else if (energyLoading) {
    const arr = Array.from(Array(numberOfRenderElements).keys());

    render = (
      <>
        {searchComponent}
        {counterComponent}
        <InfiniteScroll dataLength={arr.length} className={classes.container}>
          {arr.map((energy) => (
            <EnergyItem key={energy} skeleton="true" />
          ))}
        </InfiniteScroll>
      </>
    );
  }
  //energys loaded error
  else if (energyLoadingError) {
    render = (
      <PageError
        status={energyLoadingError.status}
        error="Failed to fetch energy"
        errorMessage={`${energyLoadingError.message}!`}
        redirect={false}
      />
    );
  }
  //energys loaded but there is no one
  else if (!energyLoading && energys.length <= 0) {
    render = (
      <div className={classes2.searchMessageContainer}>
        <div className={classes2.searchMessage}>
          <FcSearch className={classes2.icon} />
        </div>
        <p>
          Sorry! No result found <BiSad className={classes2.sadIcon} />
        </p>
      </div>
    );
  }
  return render;
};

export default SearchEnergyList;
