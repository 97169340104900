export const cssColors = {
  c_background: "#1f121f",
  c_main: "#6ca9eb",
  c_main_dark: "#33557a",
  c_main_2: "#e45be0",
  c_main_2_light: "#5e3b5c",
  c_main_2_dark: "#900191",
  c_main_2_dark_rgb: "144, 1, 145",
  c_main_2_darkest: "#2c0f2c",
  c_main_2_darkest_light: "#301530",
  c_main_2_darkest_1: "rgb(74, 26, 78)",
  c_main_2_darkest_2: "rgb(54, 16, 58)",
  c_error_red: "rgb(235, 98, 98)",
  c_error_red_rgb: "235, 98, 98",
  c_green: "rgb(68, 131, 68)",
};
