import React from "react";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import EnergyList from "../components/EnergyList/EnergyList";
import Footer from "../components/Footer/Footer";

const Home = () => {
  document.title = "Nedim Džanić - Energy";

  return (
    <PageWrapper>
      <Header />
      <EnergyList />
      <Footer />
    </PageWrapper>
  );
};

export default Home;
