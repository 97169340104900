import React from "react";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import AdminPanelForm from "../components/UI/Forms/AdminPanelForm/AdminPanelForm";

const AdminPanel = ({ title }) => {
  document.title = title;

  return (
    <PageWrapper>
      <Header />
      <AdminPanelForm title="Admin Panel" submitButton={"Add"} />
      <Footer />
    </PageWrapper>
  );
};

export default AdminPanel;
