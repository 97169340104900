import React from "react";

import classes from "./Input.module.css";

const Input = ({
  type = "text",
  placeholder,
  id,
  style,
  onChange,
  autoComplete = "on",
  error = false,
  label = false,
  labelText = "",
  value,
}) => {
  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        style={style}
        spellCheck="false"
        onChange={onChange}
        autoComplete={autoComplete}
        className={
          error ? `${classes.input} ${classes.inputError}` : classes.input
        }
        value={value}
      />
      {label && (
        <label htmlFor={id} className={classes.inputLabel}>
          {labelText}
        </label>
      )}
    </>
  );
};

export default Input;
