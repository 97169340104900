import React from "react";

import classes from "./Tag.module.css";
import { FaTimes } from "react-icons/fa";
import Flag from "react-country-flag";
import { MdOutlineRadioButtonChecked } from "react-icons/md";

const Tag = ({
  type = "normal",
  title = "",
  borderColor,
  onClick,
  flag = "",
  selected = false,
  textTransform = "none",
  children,
}) => {
  return (
    <div className={classes.container} style={{ borderColor: borderColor }}>
      {!!flag && (
        <Flag
          countryCode={flag}
          svg
          title={flag}
          style={{ fontSize: "26px", marginRight: "3px" }}
        />
      )}
      <div className={classes.title} style={{ textTransform: textTransform }}>
        {title}
      </div>
      {type === "normal" && (
        <FaTimes
          className={
            title.length > 0 ? `${classes.icon} ${classes.space}` : classes.icon
          }
          onClick={onClick}
        />
      )}
      {type === "radio" && (
        <MdOutlineRadioButtonChecked
          selected={selected}
          className={
            selected
              ? title.length > 0
                ? `${classes.icon} ${classes.space} ${classes.selected}`
                : `${classes.icon} ${classes.selected}`
              : title.length > 0
              ? `${classes.icon} ${classes.space}`
              : classes.icon
          }
          onClick={onClick}
        />
      )}
      {children}
    </div>
  );
};

export default Tag;
