import React from "react";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import Error404 from "../components/UI/Error404/Error404";
import Footer from "../components/Footer/Footer";

const Page404 = () => {
  document.title = "Nedim Džanić - Energy 404";

  return (
    <PageWrapper>
      <Header />
      <div style={{ marginTop: "40px" }}>
        <Error404 errorText="Page not found" />
      </div>
      <Footer />
    </PageWrapper>
  );
};

export default Page404;
