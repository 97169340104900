import React from "react";

import classes from "./FormWrapper.module.css";
import { FaTimes } from "react-icons/fa";
import Backdrop from "../../Backdrop/Backdrop";
import Button from "../../Button/Button";

const FormWrapper = ({
  title,
  onSubmit,
  submitButton = "Submit",
  disabled = false,
  onClose,
  children,
  className = "",
}) => {
  return (
    <Backdrop>
      <form
        onSubmit={onSubmit}
        className={
          className.length > 0 ? `${classes.form} ${className}` : classes.form
        }
      >
        <FaTimes className={classes.closeIcon} onClick={onClose} />
        <h1>{title}</h1>
        <div className={classes.formContainer}>
          <div className={classes.inputContainer}>{children}</div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            title={submitButton}
            className={classes.button}
            disabled={disabled}
          />
        </div>
      </form>
    </Backdrop>
  );
};

export default FormWrapper;
