import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import classes from "./EnergyList.module.css";
import EnergyItem from "../EnergyItem/EnergyItem";
import ThreeDotsSpinner from "../UI/Spinners/ThreeDotsSpinner";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cssColors } from "../../styles/colors";
import PageError from "../PageError/PageError";

const EnergyList = () => {
  const energys = useSelector((state) => state.energys);
  const energyLoading = useSelector((state) => state.energyLoading);
  const energyLoadingError = useSelector((state) => state.energyLoadingError);
  const [energysRender, setEnergysRender] = useState([]);
  const [counter, setCounter] = useState(0);
  const numberOfRenderElements = 20;
  const colors = useSelector((state) => state.colors);

  //set first render group
  useEffect(() => {
    if (energys.length > 0) {
      let arr = [];
      for (let i = 0; i < numberOfRenderElements; i++) {
        if (energys[i] !== undefined) arr.push(energys[i]);
      }
      setEnergysRender(arr);
    }
  }, [energys]);

  //fetch more data on scroll down
  const fetchMoreData = () => {
    setTimeout(() => {
      const rendered = energysRender.length;
      let newRenderGroup = [];

      for (
        let i = rendered;
        i < rendered + numberOfRenderElements && i < energys.length;
        i++
      )
        newRenderGroup.push(energys[i]);

      setEnergysRender([...energysRender, ...newRenderGroup]);
    }, 2000);
  };

  useEffect(() => {
    if (counter < energys.length) {
      const interval = setInterval(() => {
        setCounter((prevState) => prevState + 1);
      }, 5);

      return () => clearInterval(interval);
    }
  }, [counter, energys.length]);

  let render;
  let counterComponent = (
    <div className={classes.counterContainer}>
      <CircularProgressbarWithChildren
        value={energysRender.length > 0 && !energyLoading ? counter : 0}
        maxValue={
          energysRender.length > 0 && !energyLoading ? energys.length : 100
        }
        text={energysRender.length > 0 && !energyLoading ? counter : ""}
        styles={{
          root: { width: "90px" },
          path: {
            stroke: cssColors.c_main_2_dark,
            strokeLinecap: "butt",
            transition: "stroke-dashoffset 0.1s ease 0s",
            strokeWidth: "8px",
          },
          trail: {
            stroke: cssColors.c_main_2_darkest_1,
            strokeLinecap: "butt",
            strokeWidth: "4px",
          },
          text: {
            fill: cssColors.c_main,
            fontSize: "35px",
            fontFamily: "Roboto, Arial",
          },
        }}
      >
        {energyLoading && (
          <ThreeDotsSpinner className={classes.loadingDotsInCircleProgress} />
        )}
      </CircularProgressbarWithChildren>
    </div>
  );

  //energys loaded and energys length not 0
  if (energysRender.length > 0 && !energyLoading) {
    render = (
      <>
        {counterComponent}
        <InfiniteScroll
          dataLength={energysRender.length}
          next={fetchMoreData}
          hasMore={energysRender.length < energys.length ? true : false}
          loader={<ThreeDotsSpinner className={classes.loadingDots} />}
          className={classes.container}
        >
          {energysRender.map((energy) => (
            <EnergyItem
              key={energy.id + energy.imageUrl}
              id={energy.id}
              title={energy.name}
              imageUrl={energy.imageUrl}
              resizedImageUrl={energy.resizedImageUrl}
              country={energy.country}
              color={energy.color}
              colors={colors}
              amount={energy.amount}
            />
          ))}
        </InfiniteScroll>
      </>
    );
    //energys on loading
  } else if (energyLoading) {
    const arr = Array.from(Array(numberOfRenderElements).keys());

    render = (
      <>
        {counterComponent}
        <InfiniteScroll dataLength={arr.length} className={classes.container}>
          {arr.map((energy) => (
            <EnergyItem key={energy} skeleton="true" />
          ))}
        </InfiniteScroll>
      </>
    );
  }
  //energys loaded error
  else if (energyLoadingError) {
    render = (
      <PageError
        status={energyLoadingError.status}
        error="Failed to fetch energy"
        errorMessage={`${energyLoadingError.message}!`}
        redirect={false}
      />
    );
  }
  //energys loaded but there is no one
  else if (!energyLoading && energys.length <= 0) {
    render = (
      <div className={classes.loadingMessage}>
        <p>There are no energies to display!</p>
      </div>
    );
  }
  return render;
};

export default EnergyList;
