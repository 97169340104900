import React from "react";

import classes from "./Backdrop.module.css";

const Backdrop = ({
  backgroundColor = "rgba(0, 0, 0, 0.7)",
  className = "",
  onClose = false,
  children,
}) => {
  const onCloseHandler = (e) => {
    if (onClose === false) return;

    //if clicked on backdrop, not on children
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <div
      style={{ backgroundColor }}
      className={
        className ? `${classes.backdrop} ${className}` : classes.backdrop
      }
      id="backdrop"
      onClick={onCloseHandler}
    >
      {children}
    </div>
  );
};

export default Backdrop;
