import React, { useState } from "react";

import classes from "./Error404.module.css";
import Image404 from "../../../assets/404.png";
import Image from "../Image/Image";

const Error404 = ({ errorText }) => {
  const [imageLoading, setImageLoading] = useState(true);

  const imageAfterLoadHandler = () => setImageLoading(false);

  return (
    <>
      <div className={classes.imgContainer}>
        <Image
          alt={"Error 404"}
          src={Image404}
          afterLoad={imageAfterLoadHandler}
        />
      </div>
      {!imageLoading && <p className={classes.errorText}>{errorText}</p>}
    </>
  );
};

export default Error404;
