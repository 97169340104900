import React from "react";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import PageError from "../components/PageError/PageError";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const Redirect = () => {
  return (
    <PageWrapper>
      <Header />
      <PageError
        status={403}
        error="Access Denied"
        errorMessage="You don't have permission to view this page!"
        redirect={true}
      />
      <Footer />
    </PageWrapper>
  );
};

export default Redirect;
