import React from "react";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import AdminSettingsForm from "../components/UI/Forms/AdminSettingsForm/AdminSettingsForm";

const AdminSettings = ({ title }) => {
  document.title = title;

  return (
    <PageWrapper>
      <Header />
      <AdminSettingsForm title="Admin Settings" submitButton={"Edit"} />
      <Footer />
    </PageWrapper>
  );
};

export default AdminSettings;
