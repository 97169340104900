import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { useDispatch } from "react-redux";
import { energyActions } from "../../../store";

import classes from "./Navigation.module.css";
import Backdrop from "../Backdrop/Backdrop";
import Tag from "../Tag/Tag";
import ThreeDotsSpinner from "../Spinners/ThreeDotsSpinner";
import Button from "../Button/Button";

import { FaTimes } from "react-icons/fa";
import { TfiMenu } from "react-icons/tfi";
import { FaUserAlt } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { AiTwotoneSetting } from "react-icons/ai";
import { IoMdSearch } from "react-icons/io";

const Navigation = ({ onClose, onClosing = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState([]);
  const [countries, setCountries] = useState([]);

  const [search, setSearch] = useState("");
  const [color, setColor] = useState("");
  const [country, setCountry] = useState("");
  const [searchButton, setSearchButton] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/settings`
        );
        setColors(JSON.parse(res.data[0].colors));
        setCountries(JSON.parse(res.data[0].countries));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const logOutHandler = () => logout();

  const searchChangeHandler = (e) => setSearch(e.target.value);

  const colorSelectHandler = (selectedColor) => {
    color === selectedColor ? setColor("") : setColor(selectedColor);
  };

  const countrySelectHandler = (selectedCountry) => {
    country === selectedCountry ? setCountry("") : setCountry(selectedCountry);
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(energyActions.displayNavigation(false));
    navigate({
      pathname: "/search",
      search: createSearchParams({ search, color, country }).toString(),
    });
    setSearch("");
    setColor("");
    setCountry("");
  };

  useEffect(() => {
    if (
      search.trim().length > 0 ||
      color.trim().length > 0 ||
      country.trim().length > 0
    ) {
      setSearchButton(
        <div className={classes.buttonContainer}>
          <Button title="Search" type="submit" />
        </div>
      );
    } else {
      setSearchButton(null);
    }
  }, [color, country, search]);

  const navigationHandler = (test) => {
    dispatch(energyActions.displayNavigation(false));
    navigate(test);
  };

  return (
    <>
      <Backdrop backgroundColor={"rgba(0, 0, 0, 0.5)"} onClose={onClose} />

      <nav
        className={
          onClosing ? `${classes.nav} ${classes.closing}` : classes.nav
        }
      >
        <FaTimes className={classes.closeIcon} onClick={onClose} />

        <div className={classes.title}>
          <TfiMenu className={classes.menuIcon} />
          Menu
        </div>

        <ul>
          {currentUser ? (
            <>
              <li onClick={() => navigationHandler("/adminpanel")}>
                <IoMdAddCircle className={classes.icon} />
                Add New
              </li>
              <li onClick={() => navigationHandler("/adminsettings")}>
                <AiTwotoneSetting className={classes.icon} />
                Admin Settings
              </li>
              <li onClick={logOutHandler}>
                <FaSignOutAlt className={classes.icon} />
                Logout
              </li>
            </>
          ) : (
            <>
              <li onClick={() => navigationHandler("/adminlogin")}>
                <FaUserAlt className={classes.icon} />
                Login
              </li>
            </>
          )}

          <form
            className={classes.searchContainer}
            onSubmit={searchSubmitHandler}
          >
            <span>Search</span>
            <div className={classes.search}>
              <IoMdSearch className={classes.searchIcon} />
              <input
                type="text"
                placeholder="Search energy"
                onChange={searchChangeHandler}
                value={search}
              />
            </div>

            <p className={classes.sectionTitle}>Search by color</p>
            <div className={classes.selectContainer}>
              {loading && <ThreeDotsSpinner />}
              {colors.map((c) => (
                <Tag
                  key={c.code}
                  type="radio"
                  title={c.color}
                  borderColor={c.code}
                  selected={color === c.color && true}
                  onClick={() => colorSelectHandler(c.color)}
                />
              ))}
            </div>

            <p className={classes.sectionTitle}>Search by country</p>
            <div className={classes.selectContainer}>
              {loading && <ThreeDotsSpinner />}
              {countries.map((c) => (
                <Tag
                  key={c.code}
                  type="radio"
                  title={c.code}
                  flag={c.code}
                  textTransform="uppercase"
                  selected={country === c.code && true}
                  onClick={() => countrySelectHandler(c.code)}
                />
              ))}
            </div>
            {searchButton !== null && searchButton}
          </form>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
