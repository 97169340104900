import React from "react";

import { useSearchParams } from "react-router-dom";

import PageWrapper from "../components/UI/PageWrapper/PageWrapper";
import Header from "../components/Header/Header";
import SearchEnergyList from "../components/EnergyList/SearchEnergyList";
import Footer from "../components/Footer/Footer";

const Search = () => {
  document.title = "Nedim Džanić - Energy";

  const [searchParams] = useSearchParams();
  const search = {
    search: searchParams.get("search") || null,
    color: searchParams.get("color") || null,
    country: searchParams.get("country") || null,
  };

  return (
    <PageWrapper>
      <Header />
      <SearchEnergyList search={search} />
      <Footer />
    </PageWrapper>
  );
};

export default Search;
