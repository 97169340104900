import React from "react";

import classes from "./PageError.module.css";

import { RiLock2Fill } from "react-icons/ri";
import { BiErrorAlt } from "react-icons/bi";
import { TiArrowBack } from "react-icons/ti";
import { Link } from "react-router-dom";

const PageError = ({ status, error, errorMessage, redirect = false }) => {
  return (
    <div className={classes.container}>
      <div className={classes.containerText}>
        {status === 403 ? (
          <RiLock2Fill className={classes.icon} />
        ) : (
          <BiErrorAlt className={classes.icon} />
        )}
        <h1>{status}</h1>
        <h2>
          <strong>{error}</strong>
          <br />
          {errorMessage}
        </h2>

        {redirect && (
          <Link to="/" className={classes.containerButton}>
            <TiArrowBack className={classes.iconBack} />
            <p>Home</p>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PageError;
